@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";
@use "../../../../styles/zIndex.module";

:global(.sb-with-background-image) .blockTitleContainer {
  @media (min-width: $break-point-sm) {
    h2 {
      max-width: $terrace-title-max-width;
    }
  }
}

.blockTitleContainer {
  position: relative;
  margin-bottom: 1.75rem;
  text-align: left;
  transition: opacity .2s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: zIndex.$z-index-terrace-block-title;

  .innerTitleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  h2 {
    font-weight: $terrace-title-font-weight;
    @media(max-width: $break-point-sm) {
      font-size: $terrace-title-font-size-mobile;
    }
  }

  hr {
    display: inline-block;
    width: 25px;
    border: 2.5px solid var(--blocks);
    border-radius: 3px;
    vertical-align: top;
    margin-right: 1rem;
    background-color: var(--blocks);
  }

  &AboveTheFold {
    opacity: 1;
  }

  &Visible {
    display: inline-block;
  }
}
.editButtonContainer {
  @media (max-width: $break-point-sm) {
    padding-left: 50px;
  }
}

.viewMoreButtonContainer {
  padding-top: 4px;

  a {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: .15rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: $terrace-title-font-weight;
    background: white;
    box-shadow: 2px 2px 4px 2px #c0c0c0;

    @include mixins.leftToRightColorTransition(var(--secondaryNavBackground), var(--secondaryNavBackgroundTextColor));
  }
}

.titleTransitionContainer {
  @include mixins.opacityTransition(0, 1);
}

.blockTitle {
  display: inline-block;
  font-size: $terrace-title-font-size;
  text-align: left;
  button {
    margin-right: 10px;
    color: $black;

    &:hover {
      color: inherit;
      font-weight: $terrace-title-font-weight;
    }
  }

  &Placeholder {
    color: darkgrey;
    font-style: italic;
    font-weight: 400 !important;
  }
}

.title {
  width: 100%;
  max-width: 80%;
  display: inline-flex;
  .titleWrapper {
    display: table;
    width: 100%;
  }
  @media (max-width: $break-point-md) {
    max-width: 75%;
  }
  @media (max-width: $break-point-sm) {
    max-width: 100%;
    width: 100%;
    .titleWrapper {
      width: 85%;
    }
    .editDropDown {
      width: 15%;
    }
  }
}

.editDropDown {
  display: table-cell;
  height: $terrace-body-font-size;
}

.editableTitle {
  font-size: $terrace-title-font-size;
  display: table-cell;
}

.editButton {
  margin-right: 10px;
  > div {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }

  &Disabled {
    @include mixins.disabledButton;
  }
}

.editPencil{
  top: 0;
  height: 100%;
  > span {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 26px;
  }

    &Disabled {
        @include mixins.disabledButton;
    }
}
